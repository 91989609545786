<script>
  import Footer from "../components/Footer.svelte";
  import { push } from "svelte-spa-router";

  function navigateToHome() {
    push("/");
  }
</script>

<header>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <img
    src="../img/icon-noahkappers.svg"
    alt="Icon Noah Kappers"
    class="icon"
    on:click={navigateToHome}
  />
  <nav>
    <a href="tel:0031639080173" target="_blank" class="nav-afspraak"
      >Bel: +31 6 39 080 173</a
    >
  </nav>
</header>

<main>
  <div class="hero">
    <p class="hero-sub">Noah Kappers</p>
    <h1>Locatie Elst</h1>
    <p class="hero-adres">Dorpsstraat 97<br /> 6661 EJ Elst</p>
    <p class="hero-tel">
      <a href="tel:0031639080173" target="_blank">+31 6 39 08 01 73</a>
    </p>
  </div>
  <section class="content">
    <p>
      Welkom bij Noah Kappers in Elst, waar professionele knip- en
      stylingdiensten centraal staan. Gelegen in het hart van Elst, bieden wij
      een breed assortiment aan haarverzorgingsdiensten. Ons team van ervaren
      stylisten zet zich in om elke klant een kwaliteitsvolle en
      tevredenstellende ervaring te bieden.
    </p>
  </section>
  <div class="images">
    <img
      src="/img/noah-kappers-foto-1.jpg"
      alt="haarstijl-1-locatie-elst"
      aria-label="Haarstijl 1 van Noah Kappers Elst"
    />
    <img
      src="/img/noah-kappers-foto-2.jpg"
      alt="haarstijl-2-locatie-elst"
      aria-label="Haarstijl 2 van Noah Kappers Elst"
    />
    <img
      src="/img/noah-kappers-foto-4.jpg"
      alt="haarstijl-4-locatie-elst"
      aria-label="Haarstijl 3 van Noah Kappers Elst"
    />
  </div>
  <div class="images">
    <img
      src="/img/noah-kappers-foto-5.jpg"
      alt="haarstijl-5-locatie-elst"
      aria-label="Haarstijl 5 van Noah Kappers Elst"
    />
    <img
      src="/img/noah-kappers-foto-3.jpg"
      alt="haarstijl-3-locatie-elst"
      aria-label="Haarstijl 3 van Noah Kappers Elst"
    />
    <img
      src="/img/noah-kappers-foto-6.jpg"
      alt="haarstijl-6-locatie-elst"
      aria-label="Haarstijl 6 van Noah Kappers Elst"
    />
  </div>
  <section class="prijzen">
    <h2>Prijzen</h2>
    <p>
      Hieronder vindt u de prijzen van ons uitgebreide aanbod. Wij zorgen voor
      topkwaliteit in elke behandeling.
    </p>
    <h3 class="prijzen-cat">Mannen</h3>
    <div class="prijzen-tab">
      <p>Knippen</p>
      <p>20,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Knippen + baard scheren</p>
      <p>28,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Knippen + wassen</p>
      <p>22,50</p>
    </div>
    <div class="prijzen-tab">
      <p>Tondeuse</p>
      <p>15,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Epileren (wenkbrauw)</p>
      <p>10,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Epileren (wang + wenkbrauw)</p>
      <p>12,50</p>
    </div>
    <div class="prijzen-tab">
      <p>Baard scheren</p>
      <p>10,-</p>
    </div>
    <div class="prijzen-tab">
      <p>Baard modelleren</p>
      <p>12,50</p>
    </div>

    <h3 class="prijzen-cat">Kinderen</h3>
    <div class="prijzen-tab">
      <p>Knippen (kinderen t/m 12 jaar)</p>
      <p>17,50</p>
    </div>
  </section>
</main>

<Footer />

<style>
  main {
    margin-top: 20px;
    margin-bottom: 80px;
    color: #fff;
  }
  p {
    line-height: 28px;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
  }
  nav {
    display: flex;
    align-items: center;
  }
  .nav-afspraak {
    margin-left: 20px;
    border-radius: 5px;
    padding: 5px 7px;
    font-weight: 600;
    background-color: #a4fff6;
    color: #1d1f26;
  }

  .icon {
    width: 42px;
  }
  section.content {
    padding: 20px 0 5px 0;
    width: 600px;
    margin: 0 auto;
    font-size: 14px;
  }
  section.prijzen {
    padding: 10px 0;
    width: 600px;
    margin: 0 auto;
  }
  p {
    font-size: 18px;
    font-weight: 200;
  }
  h2 {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .prijzen-cat {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #1d1f26;
    background-color: #cfb29f;
    padding: 5px 10px;
    border-radius: 10px;
    display: inline-block;
  }
  .prijzen-tab {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #303443;
    padding: 15px 20px;
    border-radius: 10px;
  }
  .prijzen-tab p {
    margin: 0;
  }
  .images {
    display: flex;
    margin: 30px auto;
    width: 800px;
    gap: 30px;
  }
  .images img {
    justify-content: space-between;
    background-color: #c3ab84;
    border-radius: 25px;
    width: 31%;
    height: auto;
  }
  .hero {
    width: 800px;
    height: 333px;
    margin: 0 auto; /* Centreert de hero section */
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("/img/noahkappers-locatie-elst.jpg");
    background-color: rgba(0, 0, 0, 0.4);
    background-size: cover;
    background-position: center;
    border-radius: 25px;
    position: relative;
  }
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    border-radius: 25px;
  }

  .hero p,
  .hero h1 {
    margin: 0 auto;
    width: 600px;
    text-align: left;
    z-index: 2;
    color: #fff;
  }

  .hero-sub {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
  }

  .hero-adres {
    font-size: 1.2rem;
    margin-top: 30px !important;
    text-shadow: 2px 5px 5px rgba(0, 0, 0, 0.6);
  }

  .hero-tel {
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 10px !important;
    text-shadow: 2px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .hero-tel a {
    color: #fff;
  }

  .hero h1 {
    font-family:
      Georgia,
      Times,
      Times New Roman,
      serif;
    font-size: 4rem;
    text-shadow: 2px 5px 15px rgba(0, 0, 0, 0.6);
  }

  @media (max-width: 768px) {
    header {
      padding: 20px;
    }
    p {
      margin: 0;
    }
    h2 {
      margin: 10px 0 20px 0;
    }
    .icon {
      width: 35px;
    }
    .nav-afspraak {
      margin-left: 0;
      padding: 5px;
    }
    .nav-link {
      display: none;
    }
    .hero {
      width: calc(100% - 40px);
      height: 400px;
      margin: 20px;
    }
    .hero p,
    .hero h1 {
      width: auto;
      padding: 0 20px;
      margin: 0;
    }
    section.content,
    section.prijzen {
      width: calc(100% - 40px);
      padding: 20px;
    }
    .images {
      display: none;
    }
  }
</style>
